import {
  GET_BOOKING_LIST,
  GET_BOOKING_LIST_RESULT,
  GET_BOOKING_LIST_DETAIL,
  GET_BOOKING_DETAIL,
  GET_CHECKiN_LIST,
  GET_GARAGEINFO_BY_CODE_SUCCESS,
  GET_USERINFO_BY_GARAGEANDPHONE,
  GET_USERINFO_BY_GARAGEANDPHONE_SUCCESS
} from '../types/staff';

export const getBookingList = (code) => ({
  type: GET_BOOKING_LIST,
  payload: code,
});
export const getCheckInList = (code) => ({
  type: GET_CHECKiN_LIST,
  payload: code,
});

export const getBookingListDetail = (code,date) => ({
  type: GET_BOOKING_LIST_DETAIL,
  code: code,
  date:date
});
export const getBookingDetail = (bookingId) => ({
  type: GET_BOOKING_DETAIL,
  bookingId: bookingId,
});

export const getGarageInfoByCodeSuccess = (data) => ({
  type: GET_GARAGEINFO_BY_CODE_SUCCESS,
  payload: data
})

export const getUserDetailByGarageAndPhone = (garageCode, phone) => ({
  type: GET_USERINFO_BY_GARAGEANDPHONE,
  payload: {
    garageCode: garageCode,
    phone: phone
  }
})

export const getUserInfoByGarageAndPhoneCodeSuccess = (data) => ({
  type: GET_USERINFO_BY_GARAGEANDPHONE_SUCCESS,
  payload: data
})