import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import kioskSagas from './kiosk/saga';
import formSagas from './form/saga';
import staffSagas from './staff/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    kioskSagas(),
    formSagas(),
    staffSagas()
  ]);
}
