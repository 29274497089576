
export const GET_BOOKING_INFO = 'GET_BOOKING_INFO';
export const GET_BOOKING_INFO_SUCCESS = 'GET_BOOKING_INFO_SUCCESS';
export const CHANGE_SIGNATURE = 'CHANGE_SIGNATURE';

export const CHANGE_CONFIRM_DETAIL_VALUE = 'CHANGE_CONFIRM_DETAIL_VALUE';

export const CONFIRM_DETAILS_VALIDATION = 'CONFIRM_DETAILS_VALIDATION';

export const CHANGE_PARKING_POSITION = 'CHANGE_PARKING_POSITION';

export const PARKING_POSITION_VALIDATION = 'PARKING_POSITION_VALIDATION';

export const CHANGE_SERVICES_NOTES = 'CHANGE_SERVICES_NOTES';
export const CHANGE_SERVICE_PLAN = 'CHANGE_SERVICE_PLAN'
export const SERVICE_PLAN_VALIDATION = 'SERVICE_PLAN_VALIDATION'
export const SET_TOTAL_VALUE = 'SET_TOTAL_VALUE'
export const SET_PRE_AUTHORISATION = 'SET_PRE_AUTHORISATION'
export const SET_EXTRA_SERVICES = 'SET_EXTRA_SERVICES'
export const CHANGE_STATUS = 'CHANGE_STATUS'
export const CHANGE_CAR_DETAILS = 'CHANGE_CAR_DETAILS'

export const SET_PRECONFIRM_STATUS = 'SET_PRECONFIRM_STATUS'






































