
import {
  GET_BOOKING_INFO,
  GET_BOOKING_INFO_SUCCESS,
  CHANGE_SIGNATURE,
  CHANGE_CONFIRM_DETAIL_VALUE,
  CONFIRM_DETAILS_VALIDATION,
  CHANGE_PARKING_POSITION,
  PARKING_POSITION_VALIDATION,
  CHANGE_SERVICES_NOTES,
  CHANGE_SERVICE_PLAN,
  SERVICE_PLAN_VALIDATION,
  SET_TOTAL_VALUE,
  SET_PRE_AUTHORISATION,
  SET_EXTRA_SERVICES,
  CHANGE_STATUS,
  CHANGE_CAR_DETAILS,
  SET_PRECONFIRM_STATUS
} from '../types/form';


export const getBookingInfo = (id) => ({
  type: GET_BOOKING_INFO,
  payload: id
})

export const getBookingInfoSuccess = (data) => ({
  type: GET_BOOKING_INFO_SUCCESS,
  payload: data
})

export const changeSignature = (data) => ({
  type: CHANGE_SIGNATURE,
  payload: data
})

export const changeConfirmDetails = (name, value) => ({
  type: CHANGE_CONFIRM_DETAIL_VALUE,
  name: name,
  value: value
});

export const changeCarDetails = (name, value) => ({
  type: CHANGE_CAR_DETAILS,
  name: name,
  value: value
});

export const ConfirmDetailsValidation = (name) => ({
  type: CONFIRM_DETAILS_VALIDATION,
  payload: name,
})


export const changeParkingPosition = (position) => ({
  type: CHANGE_PARKING_POSITION,
  payload: position
})

export const ParkingValidation = () => ({
  type: PARKING_POSITION_VALIDATION,
  payload:"Select one value"
})


export const changeServicesNotes = (value) => ({
  type: CHANGE_SERVICES_NOTES,
  payload: value
})

export const changeServicePlan = (value) => ({
  type: CHANGE_SERVICE_PLAN,
  payload: value
})

export const ServicePlanValidation = () => ({
  type: SERVICE_PLAN_VALIDATION,
  payload: " Please select the service plan "
})

export const changeTotalPrice = (total_value) => ({
  type: SET_TOTAL_VALUE,
  payload: total_value
})

export const setPreAuthorisation = (value) => ({
  type: SET_PRE_AUTHORISATION,
  payload: value
})

export const changeExtraServices = (items) => ({
  type: SET_EXTRA_SERVICES,
  payload: items
})

export const changeStatus = (value) => ({
  type: CHANGE_STATUS,
  payload: value
})

export const setPreConfirmStatus = (value) => ({
  type: SET_PRECONFIRM_STATUS,
  payload: value
})









