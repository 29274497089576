import {
  GET_GARAGEINFO_BY_CODE,
  GET_GARAGEINFO_BY_CODE_SUCCESS,
  GET_USERINFO_BY_GARAGEANDPHONE_SUCCESS
} from '../types/kiosk';

const INIT_STATE = {
  loading: false,
  additional: null,
  completion: '',
  greeting: '',
  mainMenu: [],
  parking: '',
  paymentOption: '',
  screenSaver: '',
  servicePlan: '',
  theme: '',
  updatedAt: '',
  error: '',
  userInfo: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GARAGEINFO_BY_CODE:
      return { ...state, loading: true };

    case GET_GARAGEINFO_BY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        additional: action.payload.additional,
        completion: action.payload.completion,
        greeting: action.payload.greeting,
        mainMenu: action.payload.mainMenu,
        parking: action.payload.parking,
        paymentOption: action.payload.paymentOption,
        screenSaver: action.payload.screenSaver,
        servicePlan: action.payload.servicePlan,
        theme: action.payload.theme,
        updatedAt: action.payload.updatedAt
      };
    case GET_USERINFO_BY_GARAGEANDPHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload
      };

    default:
      return { ...state };
  }
};
