import {
  CHANGE_CONFIRM_DETAIL_VALUE,
  CONFIRM_DETAILS_VALIDATION,
  CHANGE_PARKING_POSITION,
  PARKING_POSITION_VALIDATION,
  CHANGE_SERVICES_NOTES,
  CHANGE_SERVICE_PLAN,
  SERVICE_PLAN_VALIDATION,
  SET_TOTAL_VALUE,
  SET_PRE_AUTHORISATION,
  SET_EXTRA_SERVICES,
  CHANGE_STATUS,
  CHANGE_CAR_DETAILS,
  GET_BOOKING_INFO_SUCCESS,
  CHANGE_SIGNATURE,
  SET_PRECONFIRM_STATUS
} from '../types/form';

const INIT_STATE = {

  bookingInfo: null,

  loading: false,
  reg_no: '',
  err_reg_no: null,

  mobile: '',
  err_mobile: null,

  mileage: '',
  err_mileage: null,

  parking_position: '',
  err_parking_position: null,

  notes: '',
  err_services: null,

  spreading_cost: true,
  err_spreading_cost: null,
  pre_authorisation: 500,
  total_price: 0,
  selected_products: [],
  signature: '',

  car_detail_status: 'none',

  car_detail: {
    reg_no: '',
    make: '',
    model: '',
    registered_year: '',
    engine_size: '',
    doors: ''
  },

  preconfirm_status: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BOOKING_INFO_SUCCESS:
      return { ...state,  bookingInfo: action.payload }

    case CHANGE_SIGNATURE:
      return {...state, signature: action.payload}

    case CHANGE_CONFIRM_DETAIL_VALUE:
      if (action.name === 'reg_no') {
        return { ...state, loading: false, reg_no: action.value }
      }

      if (action.name === 'mobile') {
        return { ...state, loading: false, mobile: action.value }
      }

      if (action.name === 'mileage') {
        return { ...state, loading: false, mileage: action.value }
      }

      case CHANGE_CAR_DETAILS:
        if (action.name === 'reg_no') {
          return { ...state,  car_detail: { ...state.car_detail, reg_no: action.value }}
        }
  
        if (action.name === 'make') {
          return { ...state,  car_detail: { ...state.car_detail, make: action.value }}

        }
  
        if (action.name === 'model') {
          return { ...state,  car_detail: { ...state.car_detail, model: action.value }}
        }

        if (action.name === 'registered_year') {
          return { ...state,  car_detail: { ...state.car_detail, registered_year: action.value }}
        }

        if (action.name === 'engine_size') {
          return { ...state,  car_detail: { ...state.car_detail, engine_size: action.value }}
        }

        if (action.name === 'doors') {
          return { ...state,  car_detail: { ...state.car_detail, doors: action.value }}
        }

    case CONFIRM_DETAILS_VALIDATION:
      if (action.payload === 'reg_no') {
        return { ...state, loading: false, err_reg_no: 'please insert valid value..' }
      }

      if (action.payload === 'mobile') {
        return { ...state, loading: false, err_mobile: 'please insert valid value..' }
      }

      if (action.payload === 'mileage') {
        return { ...state, loading: false, err_mileage: 'please insert valid value..' }
      }

    case CHANGE_PARKING_POSITION:
      return { ...state, loading: false, parking_position: action.payload }

    case PARKING_POSITION_VALIDATION:
      return { ...state, err_parking_position: action.payload }

    case CHANGE_SERVICES_NOTES:
      return {...state, notes: action.payload}

    case CHANGE_SERVICE_PLAN:
      return {...state, spreading_cost: action.payload}

    case SERVICE_PLAN_VALIDATION:
      return {...state, err_spreading_cost: action.payload}

    case SET_TOTAL_VALUE:
      return {...state, total_price: action.payload}

    case SET_PRE_AUTHORISATION:
      return {...state, pre_authorisation: action.payload}

    case SET_EXTRA_SERVICES:
      return {...state, selected_products: action.payload}  

    case CHANGE_STATUS: 
     return {...state, car_detail_status: action.payload}

    case SET_PRECONFIRM_STATUS: 
     return {...state, preconfirm_status: action.payload}  
    default:
      return { ...state };
  }
};
