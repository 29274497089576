import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_BOOKING_LIST, GET_BOOKING_LIST_DETAIL, GET_USERINFO_BY_GARAGEANDPHONE } from '../types/staff';
import api from '../../ApiConfig';
import {
  GET_BOOKING_LIST_RESULT,
  GET_BOOKING_ID,
  GET_BOOKING_DETAIL,
  GET_BOOKING_DETAIL_RESULT,
} from '../types/staff'
export function* watchGetBookingList() {
  yield takeEvery(GET_BOOKING_LIST, getBookingList);
}

function* getBookingList({ payload }) {
  try {
    const result = yield call(getBookingListAsync, payload);
    if (result) {
      yield put({
        type: GET_BOOKING_LIST_RESULT,
        value: result
      })
    }
  } catch (error) {
    console.log('error =>', error);
  }
}

const getBookingListAsync = async (code) => {
  return api.get(`auassist/bookings/list/${code}`).then((res) => res.data.result).catch((error) => error);
};

export function* watchGetBookingListDetail() {
  yield takeEvery(GET_BOOKING_LIST_DETAIL, getBookingListDetails);
}

function* getBookingListDetails({ code, date }) {
  try {
    const result = yield call(getBookingListDetailAsync, code, date);
    if (result) {
      if (result.length > 0) {
        yield put({
          type: GET_BOOKING_ID,
          value: result[0].bookingId
        })
      }else{
        yield put({
          type: GET_BOOKING_ID,
          value: ""
        })
        yield put({
          type: GET_BOOKING_DETAIL_RESULT,
          booking: [],
          checkIn: []
        }) 
      }
    }else{
      yield put({
        type: GET_BOOKING_ID,
        value: ""
      })
      yield put({
        type: GET_BOOKING_DETAIL_RESULT,
        booking: [],
        checkIn: []
      })
    }
  } catch (error) {
    console.log('error =>', error);
  }
}

const getBookingListDetailAsync = async (code, date) => {
  return api.get(`auassist/checkin/${code}?d=${date}`).then((res) => res.data.result).catch((error) => error);
};



export function* watchGetBookingDetail() {
  yield takeEvery(GET_BOOKING_DETAIL, getBookingDetail);
}

function* getBookingDetail({ bookingId }) {
  try {
    const result = yield call(getBookingDetailAsync, bookingId);
    if (result) {
      if (result.booking) {
        yield put({
          type: GET_BOOKING_DETAIL_RESULT,
          booking: result.booking,
          checkIn: result.checkin
        })
      } else {
        console.log("&&&&&&&&&&&&&&", "********************")
        yield put({
          type: GET_BOOKING_DETAIL_RESULT,
          booking: [],
          checkIn: []
        })
      }
    } else {
      console.log("&&&&&&&&&&&&&&", "********************")
      yield put({
        type: GET_BOOKING_DETAIL_RESULT,
        booking: [],
        checkIn: []
      })
    }
  } catch (error) {
    console.log('error =>', error);
  }
}

const getBookingDetailAsync = async (bookingId) => {
  return api.get(`auassist/bookings/${bookingId}`).then((res) => res.data.result).catch((error) => error);
};


export default function* rootSaga() {
  yield all([
    fork(watchGetBookingList),
    fork(watchGetBookingListDetail),
    fork(watchGetBookingDetail),
  ]);
}