import {
  GET_GARAGEINFO_BY_CODE,
  GET_GARAGEINFO_BY_CODE_SUCCESS,
  GET_USERINFO_BY_GARAGEANDPHONE,
  GET_USERINFO_BY_GARAGEANDPHONE_SUCCESS
} from '../types/kiosk';

export const getGarageInfoByCode = (code) => ({
  type: GET_GARAGEINFO_BY_CODE,
  payload: code,
});

export const getGarageInfoByCodeSuccess = (data) => ({
  type: GET_GARAGEINFO_BY_CODE_SUCCESS,
  payload: data
})

export const getUserDetailByGarageAndPhone = (garageCode, phone) => ({
  type: GET_USERINFO_BY_GARAGEANDPHONE,
  payload: {
    garageCode: garageCode,
    phone: phone
  }
})

export const getUserInfoByGarageAndPhoneCodeSuccess = (data) => ({
  type: GET_USERINFO_BY_GARAGEANDPHONE_SUCCESS,
  payload: data
})