import {
  GET_BOOKING_LIST,
  GET_BOOKING_ID,
  GET_BOOKING_LIST_RESULT,
  GET_BOOKING_DETAIL_RESULT,
  GET_GARAGEINFO_BY_CODE_SUCCESS,
  GET_USERINFO_BY_GARAGEANDPHONE_SUCCESS
} from '../types/staff';

const INIT_STATE = {
  loading: false,
  bookingList: [],
  bookingId: "",
  booking:[],
  checkIn:[],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BOOKING_LIST:
      return { ...state, loading: true };
    case GET_BOOKING_LIST_RESULT:
      return { ...state, bookingList: action.value };
    case GET_BOOKING_ID:
      return { ...state, bookingId: action.value };
    case GET_BOOKING_DETAIL_RESULT:
      return { ...state, booking: action.booking,checkIn: action.checkIn };
    default:
      return { ...state };
  }
};
