import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_GARAGEINFO_BY_CODE, GET_USERINFO_BY_GARAGEANDPHONE } from '../types/kiosk';
import api from '../../ApiConfig';
import { getGarageInfoByCodeSuccess, getUserInfoByGarageAndPhoneCodeSuccess } from './actions'

export function* watchGetGarageInfoByCode() {
  yield takeEvery(GET_GARAGEINFO_BY_CODE, getGarageInfoByCode);
}

function* getGarageInfoByCode({payload}) {
  try {
    const result = yield call(getGarageInfoByCodeAsync, payload);
    if (result) {
      yield put(getGarageInfoByCodeSuccess(result));
    } 
  } catch (error) {
    console.log('error =>', error);
  }
}

const getGarageInfoByCodeAsync = async (code) => {
  return api.get(`/auassist/garageInfo/${code}`).then((res) => res.data.result).catch((error) => error);
};

export function* watchGetUserInfoByGarageAndPhoneCode() {
  yield takeEvery(GET_USERINFO_BY_GARAGEANDPHONE, getUserInfoByGarageAndPhoneCode);
}

function* getUserInfoByGarageAndPhoneCode({payload}) {
  try {
    const result = yield call(getUserInfoByGarageAndPhoneCodeAsync, payload);
    if (result) {
      yield put(getUserInfoByGarageAndPhoneCodeSuccess(result));
    } 
  } catch (error) {
    console.log('error =>', error);
  }
}

const getUserInfoByGarageAndPhoneCodeAsync = async (data) => {
  return api.get(`/auassist/user/${data.garageCode}/${data.phone}`).then((res) => res.data.result).catch((error) => error);
};


export default function* rootSaga() {
  yield all([
    fork(watchGetGarageInfoByCode),
    fork(watchGetUserInfoByGarageAndPhoneCode),
  ]);
}