import { combineReducers } from 'redux';
import settings from './settings/reducer';
import authUser from './auth/reducer';
import kiosk from './kiosk/reducer';
import form from './form/reducer';
import staff from './staff/reducer';

const reducers = combineReducers({
  settings,
  authUser,
  kiosk,
  form,
  staff
});

export default reducers;
