export const GET_BOOKING_LIST = 'GET_BOOKING_LIST';
export const GET_BOOKING_LIST_RESULT = 'GET_BOOKING_LIST_RESULT';
export const GET_BOOKING_LIST_DETAIL = 'GET_BOOKING_LIST_DETAIL';
export const GET_BOOKING_ID = 'GET_BOOKING_ID';
export const GET_BOOKING_DETAIL = 'GET_BOOKING_DETAIL';
export const GET_BOOKING_DETAIL_RESULT = 'GET_BOOKING_DETAIL_RESULT';
export const GET_CHECKiN_LIST = 'GET_CHECKiN_LIST';
export const GET_GARAGEINFO_BY_CODE_SUCCESS = 'GET_GARAGEINFO_BY_CODE_SUCCESS';
export const GET_USERINFO_BY_GARAGEANDPHONE = 'GET_USERINFO_BY_GARAGEANDPHONE';
export const GET_USERINFO_BY_GARAGEANDPHONE_SUCCESS = 'GET_USERINFO_BY_GARAGEANDPHONE_SUCCESS';









