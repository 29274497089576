export const GET_GARAGEINFO_BY_CODE = 'GET_GARAGEINFO_BY_CODE';
export const GET_GARAGEINFO_BY_CODE_SUCCESS = 'GET_GARAGEINFO_BY_CODE_SUCCESS';
export const GET_USERINFO_BY_GARAGEANDPHONE = 'GET_USERINFO_BY_GARAGEANDPHONE';
export const GET_USERINFO_BY_GARAGEANDPHONE_SUCCESS = 'GET_USERINFO_BY_GARAGEANDPHONE_SUCCESS';









