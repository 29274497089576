
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_BOOKING_INFO } from '../types/form';
import { getBookingInfoSuccess } from './actions'
import api from '../../ApiConfig';


export function* watchGetBookingInfo() {
  yield takeEvery(GET_BOOKING_INFO, getBookingInfo);
}

function* getBookingInfo({payload}) {
  try {
    const result = yield call(getBookingInfoAsync, payload);
    if (result) {
      yield put(getBookingInfoSuccess(result));
    } else {
      console.log('api call failed')
    }
  } catch (error) {
    console.log('error =>', error)
  }

}


const getBookingInfoAsync = async (id) => {
  return api.get(`/auassist/bookings/${id}`).then((res) => res.data.result).catch(console.log);
};

export default function* rootSaga() {
  yield all([
    fork(watchGetBookingInfo),
  ]);
}